<template>
  <div v-if="load && list && list.length > 0" class="hot-sales">
    <safe-area>
      <div class="hot-sale-title">
        <span>热销产品</span><a class="more" href="/product/index">查看全部<i class="el-icon-arrow-right"></i></a>
      </div>
      <div class="hot-sale-items">
        <a v-for="item in list" :href="`/product/format?id=${item.id}&productId=${item.productId}`"
           class="hot-sale-item">
          <img :src="item.thumbnail"
               class="hot-sale-image"/>
          <div class="hot-sale-name">{{ item.name }}</div>
          <div class="hot-sale-amount">¥{{ item.amount }}</div>
          <div class="hot-sale-info">
            <div
                :class="item.stock === 0 ?'hot-sale-stock hot-sale-stock-invalid':'hot-sale-stock hot-sale-stock-valid'">
              {{ item.stock === 0 ? '货期请咨询' : '现货' }}
              <span v-if="item.stock > 0">{{ item.stock }}件</span>
            </div>
            <div class="hot-sale-volume">已售
              <span>{{ item.sales }}件</span>
            </div>
          </div>
        </a>
      </div>
      <!--      <img class="ad" src=""/>-->
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetIndexHotFormatApi} from "@/api/product";

export default {
  name: "host-sales",
  components: {SafeArea},
  data() {
    return {
      load: false,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      GetIndexHotFormatApi().then(res => {
        this.load = true
        this.list = res
      })
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 755px) {
  .hot-sales {

    padding: 20px 5px 10px;
  }

  .hot-sale-title {
    font-size: 1.2rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .hot-sale-items {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .hot-sale-item {
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    background-color: #ffffff;
    margin: 10px 5px 0;
  }

  .hot-sale-item:nth-child(5) { /*移动端第五个不展示*/
    display: none;
  }

  .hot-sale-image {
    display: block;
    width: 100%;
    margin: 0;
  }

  .hot-sale-name {
    padding: 10px;
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
  }

  .hot-sale-amount {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: #333333;
    padding: 8px 0;
  }

  .hot-sale-info {
    color: #888888;
    display: flex;
    padding: 15px 0;
    text-align: center;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  .hot-sale-stock {
    flex: 0 0 calc(50% + 1px);
    border-right: 1px solid #eeeeee;
  }

  .hot-sale-stock-invalid {
    color: #c9151e;
  }

  .hot-sale-stock-valid span {
    color: #009944;
  }

  .hot-sale-volume {
    flex: 0 0 calc(50% - 1px);
  }

  .hot-sale-volume span {

    color: #c9151e;
  }

  .ad {
    width: 100%;
    margin: 10px 0 0;
    display: none;

  }
}

@media screen and (min-width: 756px) {
  .hot-sales {
    padding-top: 40px;
  }

  .hot-sale-title {
    font-size: 1.5rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .hot-sale-items {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  .hot-sale-item {
    flex: 0 0 calc(20% - 8px);
    width: calc(20% - 8px);
    background-color: #ffffff;
    border-top: 3px solid #eeeeee;
    transition: all 0.3s linear;
    margin-top: 7px;
    margin-right: 10px;
  }

  .hot-sale-item:hover {
    border-top: 3px solid #c9151e;
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.1);
  }

  .hot-sale-item:hover .hot-sale-name {
    color: #c9151e;
    transition: all 0.3s linear;
  }

  .hot-sale-item:last-child {
    margin-right: 0;
  }

  .hot-sale-image {
    display: block;
    width: 100%;
    margin: 0;
  }

  .hot-sale-name {
    padding: 10px;
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
  }

  .hot-sale-amount {
    text-align: center;
    font-size: 0.8rem;
    font-weight: bold;
    color: #333333;
    padding: 8px 0;
  }

  .hot-sale-info {
    color: #888888;
    display: flex;
    padding: 15px 0;
    text-align: center;
    justify-content: space-between;
    font-size: 0.8rem;
  }

  .hot-sale-stock {
    flex: 0 0 calc(50% + 1px);
    border-right: 1px solid #eeeeee;
  }

  .hot-sale-stock-invalid {
    color: #c9151e;
  }

  .hot-sale-stock-valid span {
    color: #009944;
  }

  .hot-sale-volume {
    flex: 0 0 calc(50% - 1px);
  }

  .hot-sale-volume span {

    color: #c9151e;
  }

  .ad {
    width: 100%;
    margin: 10px 0 0;
    display: block;

  }
}
</style>
