<template>
  <div v-if="load && banner && banner.length > 0" class="banner">
    <safe-area>
      <div class="banner-area">
        <expand-menu style="position: absolute;top:0;left: 0;z-index: 999"></expand-menu>
        <el-carousel :height="height" arrow="never">
          <el-carousel-item v-for="(item,i) in banner" :key="`banner-${i}`">
            <a :href="item.url"><img :src="item.src" class="banner-image"/></a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import ExpandMenu from "@/components/expand-menu/expand-menu";
import {GetAdvertiseApi} from "@/api/advertise";

export default {
  name: "banner",
  components: {ExpandMenu, SafeArea},
  data() {
    return {
      banner: [],
      height: '',
      load: false
    }
  },
  mounted() {
    this.setHeight()
    this.getAds()
    const that = this
    window.onresize = () => {
      return (() => {
        that.setHeight()
      })()
    }
  },
  methods: {
    setHeight() {
      this.height = window.innerWidth >= 756 ? '500px' : (window.innerWidth / 2.532).toFixed(1) + 'px'
    },
    getAds() {
      GetAdvertiseApi({position: 0}).then(res => {
        this.load = true
        this.banner = res
      })
    }
  },
  beforeDestroy() {

  }
}
</script>

<style scoped>
.banner {
  background-color: #ffffff;
}

.banner-image {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
}

.banner-area {
  position: relative;
}
</style>
