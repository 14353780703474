<template>
  <div class="index">
    <banner></banner>
    <shortcut></shortcut>
    <!--    <host-sales></host-sales>-->
    <category-product></category-product>
    <video-list></video-list>
    <div class="blank-height"></div>
  </div>
</template>

<script>
import Banner from "@/components/index/banner";
import Shortcut from "@/components/index/shortcut";
import HostSales from "@/components/index/host-sales";
import CategoryProduct from "@/components/index/category-product";
import VideoList from "@/components/index/video-list";

export default {
  name: "index",
  components: {VideoList, CategoryProduct, HostSales, Shortcut, Banner}
}
</script>

<style scoped>
.index {
  background-color: #eeeeee;
}

.blank-height {
  height: 10px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}
</style>
