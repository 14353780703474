import request from '@/utils/request'
import md5 from 'md5'

export function GetAdvertiseApi(params) {
    return request.post(
        process.env.VUE_APP_API_HOST + '/banner/list',
        params,
        {}
    )
}
