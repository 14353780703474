<template>
  <div class="shortcut-com">
    <safe-area>
      <div class="shortcut">
        <div class="shortcut-link">
          <a class="shortcut-link-item" href="/tech/tool">
            <img src="../../assets/shortcut/calc.png"/>
            <span>{{lang === 'en'?'Calculator':'计算支持'}}</span>
          </a>
          <div class="shortcut-link-line-vertical"></div>
          <a class="shortcut-link-item" href="/about/information">
            <img src="../../assets/shortcut/activity.png"/>
            <span>{{lang === 'en'?'Exhibition':'展会信息'}}</span>
          </a>
          <div class="shortcut-link-line-vertical"></div>
          <a class="shortcut-link-item" href="/tech/post">
            <img src="../../assets/shortcut/tech-post.png"/>
            <span>{{ lang === 'en'?'Technical':'技术文章' }}</span>
          </a>
          <div class="shortcut-link-line-horizontal"></div>
          <div class="shortcut-link-line-horizontal"></div>
          <div class="shortcut-link-line-horizontal"></div>
          <a class="shortcut-link-item" href="/about/news">
            <img src="../../assets/shortcut/shortcut-1.png"/>
            <span>{{lang === 'en'?'News':'公司动态'  }}</span>
          </a>
          <div class="shortcut-link-line-vertical"></div>
          <a class="shortcut-link-item" href="/about/profile">
            <img src="../../assets/shortcut/about.png"/>
            <span>{{ lang === 'en'?'About':'公司介绍' }}</span>
          </a>
          <div class="shortcut-link-line-vertical"></div>
          <a class="shortcut-link-item" href="/about/contact">
            <img src="../../assets/shortcut/contact.png"/>
            <span>{{lang === 'en'?'Contact':'联系我们'}}</span>
          </a>
        </div>
        <div v-if="load && ads && ads.length > 0" class="image-ad">
          <a v-for="item in ads" :href="item.url" class="image-ad-item">
            <img :src="item.src"/>
          </a>
        </div>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetAdvertiseApi} from "@/api/advertise";
import {getLang} from "@/utils/lang";

export default {
  name: "shortcut",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      ads: [],
      load: false
    }
  },
  created() {
    this.getAds()
  },
  methods: {
    getAds() {
      GetAdvertiseApi({position: 1}).then(res => {
        this.load = true
        this.ads = res
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .shortcut-com {
    padding: 10px 0;
    background-color: #ffffff;
  }

  .shortcut {
    display: flex;
  }

  .shortcut-link {
    flex: 0 0 225px;
    background-color: #a7a5b1;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    width: 225px;
    align-items: center;
    min-height: 180px;
  }

  .shortcut-link a {
    color: #ffffff;
  }

  .shortcut-link-item {
    flex: 0 0 74px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .shortcut-link-item img {
    display: block;
    width: 20px;
    margin: 0 auto;
  }

  .shortcut-link-item span {
    font-size: 0.7rem;
    margin-top: 10px;
  }


  .shortcut-link-line-vertical {
    width: 1px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  .shortcut-link-line-horizontal {
    flex: 1 1 auto;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 50px;
    margin: 0px 10px;
  }

  .image-ad {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow: auto;
  }

  .image-ad-item {
    flex: 0 0 calc(33.3% - 10px);
    display: flex;
    margin-left: 10px;
  }

  .image-ad-item img {
    display: block;
    width: 100%;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;

  }

}

@media screen and (max-width: 755px) {
  .shortcut-com {
    padding: 10px 0;
    background-color: #ffffff;
  }

  .shortcut {
    display: block;
  }

  .shortcut-link {
    background-color: #a7a5b1;
    color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    margin: 0 10px;
    align-items: center;
    padding: 10px 0;
  }

  .shortcut-link a {
    color: #ffffff;
  }

  .shortcut-link-item {
    flex: 0 0 calc(33.11% - 2px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 0;
  }

  .shortcut-link-item img {
    display: block;
    width: 20px;
    margin: 0 auto;
  }

  .shortcut-link-item span {
    font-size: 0.7rem;
    margin-top: 10px;
  }


  .shortcut-link-line-vertical {
    width: 1px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.3);
    display: none;
  }

  .shortcut-link-line-horizontal {
    flex: 1 1 auto;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 50px;
    margin: 0px 10px;
    display: none;
  }

  .image-ad {
    display: flex;
    flex: 1;
    flex-wrap: nowrap;
    overflow: auto;
    margin: 10px 0 0;
    padding-right: 10px;
  }

  .image-ad-item {
    flex: 1;
    display: flex;
    margin-left: 10px;

  }

  .image-ad-item:nth-child(3) {
    display: none;
  }

  .image-ad-item img {
    display: block;
    width: 100%;
    flex: 1 1 auto;
    margin: 0;
    padding: 0;

  }

}
</style>
