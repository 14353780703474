<template>
  <div v-if="load && list && list.length > 0">
    <div v-for="(item,i) in list" :key="`category-${i}`">
      <div class="category">
        <safe-area>
          <div class="category-area">
            <div class="category-title"><span>{{lang ==='en'? item.nameEn:item.name }}</span><a href='/product/index'
                                                                       class="more">{{lang === 'en'?'More':'查看全部'}}<i
                class="el-icon-arrow-right"></i></a></div>
            <div class="category-goods">
              <div class="category-index-image">
                <img :src="item.indexAdLeft"/>
              </div>
              <div v-if="item.product" class="category-goods-items">
                <template v-for="(child) in item.product">
                  <a
                      :href='`/product/list?id=${child.id}`'
                      class="category-goods-item">
                    <img :src="child.thumbnail"
                         class="category-goods-image"/>
                    <div class="category-goods-name">{{ lang === 'en'?child.nameEn:child.name }}</div>
                    <div class="goods-info">
                      <div class="format">{{lang === 'en'?'':'产品'}} <span>{{ child.formatNumber }} {{lang === 'en'?'Types':'种'}}</span></div>
                      <!--                      <div class="sales-volume">已售 <span>{{ child.salesNumber }}件</span></div>-->
                    </div>
                  </a>
                </template>

              </div>
            </div>
            <a v-if="item.indexAdBottom" :href="item.indexAdBottomUrl"><img :src="item.indexAdBottom"
                                                                            class="ad"/></a>
          </div>
        </safe-area>
      </div>
    </div>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetIndexProductGroupByCategoryApi} from "@/api/product";
import {getLang} from "@/utils/lang";

export default {
  name: "category-product",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      list: [],
      load: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      GetIndexProductGroupByCategoryApi().then(res => {
        this.load = true
        this.list = res
      })
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 755px) {
  .category-area {
    padding: 20px 5px 10px;
  }

  .category-title {
    padding: 0 5px;
    font-size: 1.5rem;
    color: #333;
  }

  .category-title {
    font-size: 1.2rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .category-goods {
    padding-top: 10px;
    display: flex;
  }

  .category-index-image {
    flex: 0 0 225px;
    width: 225px;
    margin-top: 10px;
    display: none;
  }

  .category-index-image img {
    width: 100%;
    display: none;
    margin: 0;
  }

  .category-goods-items {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }


  .category-goods-item {
    flex: 0 0 calc(50% - 10px);
    background-color: #ffffff;
    max-height: 339px;
    width: calc(50% - 10px);
    margin: 10px 5px 0;
  }

  .category-goods-item:hover {
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.1);
  }

  .category-goods-item:hover .category-goods-name {
    color: #c9151e;
    transition: all 0.3s linear;
  }

  .category-goods-name {
    padding: 10px;
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
  }

  .category-goods-image {
    width: 100%;
  }

  .ad {
    width: 100%;
    display: block;
    margin: 10px 0 0;
    display: none;
  }

  .goods-info {
    color: #888888;
    display: flex;
    padding: 15px 0;
    text-align: center;
    justify-content: center;
    font-size: 0.8rem;
  }

  .format {
    flex: 0 0 calc(50% + 1px);
    /*border-right: 1px solid #eeeeee;*/
  }

  .format span {
    color: #c9151e;
  }

  .sales-volume span {
    color: #009944;
  }

  .sales-volume {
    flex: 0 0 calc(50% - 1px);
  }
}

@media screen and (min-width: 756px) {
  .category-area {
    padding-top: 40px;
  }

  .category-title {
    font-size: 1.5rem;
    color: #333;
  }

  .category-title {
    font-size: 1.5rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .category-goods {
    padding-top: 10px;
    display: flex;
  }

  .category-index-image {
    flex: 0 0 225px;
    width: 225px;
    margin-top: 10px;
  }

  .category-index-image img {
    width: 100%;
    display: block;
    margin: 0;
  }

  .category-goods-items {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }


  .category-goods-item {
    flex: 0 0 calc(25% - 10px);
    background-color: #ffffff;
    margin-left: 10px;
    margin-top: 10px;
    max-height: 339px;
    width: calc(25% - 10px);
  }

  .category-goods-item:hover {
    box-shadow: 1px 1px 10px 5px rgba(0, 0, 0, 0.1);
  }

  .category-goods-item:hover .category-goods-name {
    color: #c9151e;
    transition: all 0.3s linear;
  }

  .category-goods-name {
    padding: 10px;
    text-align: center;
    color: #333333;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
  }

  .category-goods-image {
    width: 100%;
  }

  .ad {
    width: 100%;
    display: block;
    margin: 10px 0 0;
  }

  .goods-info {
    color: #888888;
    display: flex;
    padding: 15px 0;
    text-align: center;
    justify-content: center;
    font-size: 0.8rem;
  }

  .format {
    flex: 0 0 calc(50% + 1px);
    /*border-right: 1px solid #eeeeee;*/
  }

  .format span {
    color: #c9151e;
  }

  .sales-volume span {
    color: #009944;
  }

  .sales-volume {
    flex: 0 0 calc(50% - 1px);
  }
}
</style>
