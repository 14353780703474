<template>
  <div class="expand-menu" @mouseleave="leaveExpand">
    <div class="menus">
      <div v-for="(item,i) in menu" :class="i !== menuIndex ? 'top-item':'top-item top-item-hover'"
           @mouseenter="enter(i)">
        <span>{{lang ==='en'? item.nameEn:item.name }}</span><i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div v-if="menuIndex >= 0" class="expand">
      <div class="expand-items">
        <template v-for="child in menu[menuIndex].product">
          <a :href="`/product/list?id=${child.id}`" class="expand-item">
            <img :src="child.thumbnail" class="thumbnail">
            <div class="expand-name"> {{lang ==='en'? child.nameEn:child.name }}</div>
          </a>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import {GetProductGroupByCategoryApi} from "@/api/product";
import {getLang} from "@/utils/lang";

export default {
  name: "expand-menu",
  data() {
    return {
      lang: getLang(),
      menuIndex: -1,
      inExpand: false,
      menu: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    enter(e) {
      this.menuIndex = e
    },
    leaveExpand() {
      this.menuIndex = -1
    },
    getList() {
      GetProductGroupByCategoryApi().then(res => {
        this.menu = res
      })
    }
  }
}
</script>

<style scoped>
.expand-menu {
  z-index: 9999;
  height: 100%;
  display: flex;
  width: auto;
}

.menus {
  flex: 0 0 225px;
  width: 225px;
  padding: 20px 0;
  height: calc(100% - 40px);
  background-color: rgba(105, 101, 101, .6);
  overflow: auto;
  box-shadow: 0 0 15px -8px rgb(0 0 0 / 50%);
}

.top-item {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 25px;
  font-size: 0.9rem;
}

.top-item span {
  display: block;
  flex: 0 0 160px;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-item-hover {
  background-color: #C9151E;
}

.expand {
  background-color: #ffffff;
  width: 850px;
  overflow: auto;
  padding: 30px;
  box-shadow: 2px 1px 5px 3px rgba(0, 0, 0, 0.1);
}

.thumbnail {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  margin-right: 10px;
  flex: 0 0 40px;
}

.expand-items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.expand-item {
  flex: 0 0 30%;
  width: 30%;
  display: flex;
  align-items: center;
  margin: 10px;
  font-size: 0.8rem;
  color: #666666;
}

@media screen and (max-width: 755px) {
  .expand-menu {
    display: none;
  }
}
</style>
