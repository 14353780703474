<template>
  <div v-if="load && list && list.length > 0" class="video-list">
    <safe-area>
      <div class="video-list-title">
        <span>{{ lang === 'en'?'Videos':'产品视频' }}</span>
        <!--        <a class="more" href="/tech/video">查看全部<i class="el-icon-arrow-right"></i></a>-->
      </div>
      <div class="video-list-items">
        <a v-for="item in list" :href="`/content/detail?id=${item.id}`" class="video-list-item" target="_blank">
          <div class="video-thumbnail">
            <img :src="item.thumbnail"
                 class="video-thumbnail-image"/>
            <img class="play-image" src="../../assets/index/index-video-play.png"/>
          </div>
          <div class="video-name">{{ item.title }}</div>
        </a>
      </div>
    </safe-area>
  </div>
</template>

<script>
import SafeArea from "@/layout/safe-area";
import {GetIndexVideoApi} from "@/api/video";
import {getLang} from "@/utils/lang";

export default {
  name: "video-list",
  components: {SafeArea},
  data() {
    return {
      lang: getLang(),
      load: false,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      GetIndexVideoApi().then(res => {
        this.load = true
        this.list = res
      })
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 756px) {
  .video-list {
    padding-top: 40px;
  }

  .video-list-title {
    font-size: 1.5rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .video-list-items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .video-list-item {
    background-color: #ffffff;
    width: calc(25% - 8px);
    flex: 0 0 calc(25% - 8px);
    margin-left: 10px;
    margin-top: 10px;
  }

  .video-list-item:first-child {
    margin-left: 0px;
  }

  .video-thumbnail {
    position: relative;
    width: 100%;
  }

  .video-thumbnail-image {
    width: 100%;
    display: block;
    margin: 0;
  }

  .play-image {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
  }

  .video-list-item:hover .video-name {
    color: #c9151e;
    transition: all 0.3s linear;
  }

  .video-list-item:hover .play-image {
    width: 50px;
    transition: all 0.3s linear;
  }

  .video-name {
    padding: 20px;
    text-align: center;
    color: #333333;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
    white-space: nowrap;
  }
}

@media screen and (max-width: 755px) {
  .video-list {
    padding: 20px 5px 10px;
  }

  .video-list-title {
    font-size: 1.2rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }

  .more {
    font-size: 0.8rem;
    color: #777777;
    display: flex;
    align-items: center;
  }

  .el-icon-arrow-right {
    font-size: 1rem;
    font-weight: bold;
  }

  .more:hover {
    color: #c9151e;
  }

  .video-list-items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .video-list-item {
    background-color: #ffffff;
    flex: 0 0 calc(50% - 10px);
    width: calc(50% - 10px);
    margin: 10px 5px 0;
  }

  .video-thumbnail {
    position: relative;
    width: 100%;
  }

  .video-thumbnail-image {
    width: 100%;
    display: block;
    margin: 0;
  }

  .play-image {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 40px;
  }

  .video-list-item:hover .video-name {
    color: #c9151e;
    transition: all 0.3s linear;
  }

  .video-list-item:hover .play-image {
    width: 50px;
    transition: all 0.3s linear;
  }

  .video-name {
    padding: 10px;
    text-align: center;
    color: #333333;
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
  }
}
</style>
